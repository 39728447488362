<template>
  <div class="kantiancom">
    <div
      class="box1"
      :style="availWidth<600?'height:'+ availHeight/2+'px;':'height:'+ availHeight+'px;'"
    >
      <div class="ydhtml" v-if="availWidth < 600">开始众筹</div>
      <video
        v-if="availWidth > 600"
        id="videos"
        muted="muted"
        style="width: 100%; position: absolute; top: 0"
        src="https://kantiantech.obs.cn-north-4.myhuaweicloud.com/mini/%E7%9E%B0%E5%A4%A9%E8%A7%86%E9%A2%911920x1080.6e611423.mp4"
        autoplay="autoplay"
        loop
      >
       <source src="https://kantiantech.obs.cn-north-4.myhuaweicloud.com/mini/%E7%9E%B0%E5%A4%A9%E8%A7%86%E9%A2%911920x1080.6e611423.mp4"  type="video/mp4" >
      </video>
      <video
        v-if="availWidth < 600"
        id="videos"
        muted="muted"
        style="width: 100%; position: absolute; top: 50px"
        poster="//29096929.s142i.faiusr.com/2/AI4BCOH37w0QAhgAII_u9ZQGKLSEh-MHMIAPOLgI!640x640.jpg"
        src="https://kantiantech.obs.cn-north-4.myhuaweicloud.com/mini/yidong.mp4"
        controls="controls"
        webkit-playsinlin
        loop
      ></video>
      <div class="icon" v-if="availWidth > 600">
        <img style="width: 80%; height: 80%" src="../assets/logo3.png" alt="" />
      </div>
      <div class="button" v-if="availWidth > 600">开始众筹</div>
    </div>
    <div class="div1">
      <img style="width: 100%" src="../assets/1.jpg" alt="" />
    </div>
    <div class="box2">
      <img style="width: 100%" src="../assets/2.jpg" alt="" />
    </div>
    <div class="div1">
      <img style="width: 100%" src="../assets/3.jpg" alt="" />
    </div>
    <div class="box3">
      <img style="width: 100%" src="../assets/4.jpeg" alt="" />
    </div>
    <div class="div1">
      <img style="width: 100%" src="../assets/9.jpg" alt="" />
    </div>
    <div class="box3">
      <img style="width: 100%" src="../assets/8.jpg" alt="" />
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  defineComponent,
  onBeforeUnmount,
} from "vue";

export default defineComponent({
  setup() {
    // var clientWidth = document.documentElement.clientWidth;
    var availWidth = window.screen.availWidth
    var availHeight = window.screen.availHeight;
    console.log(availWidth,availHeight,'availWidth')
    // 判断浏览器函数
    const data = reactive({});
    const methodsMap = {};
    onMounted(() => {});
    onBeforeUnmount(() => {});
    return {
      ...methodsMap,
      ...toRefs(data),
      // clientWidth,
      availWidth,
      availHeight
    };
  },
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  position: absolute;
  width: 182px;
  height: 50px;
  background-color: rgba(251, 140, 0, 1);
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
  font-weight: 700;
  font-size: 20px;
  right: 104px;
  top: 54px;
}
.ydhtml {
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: rgba(251, 140, 0, 1);
  z-index: 99;
  text-align: center;
  color: #ffffff;
  line-height: 53px;
  font-weight: 700;
  font-size: 20px;
}
.icon {
  position: absolute;
  top: 24px;
  left: 104px;
}
.kantiancom {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.box1 {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  background-color: #030303d3;
}
.box2 {
  width: 100%;
  background-color: rgb(255, 115, 0);
  display: flex;
}
.box3 {
  width: 100%;
  display: flex;
}
.div1 {
  width: 100%;
  display: flex;
  background-color: #000000;
}
</style>
